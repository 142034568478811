import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import md from "../images/md.jpg"

const IndexPage = () => {
  return(
    <Layout>
      <Helmet>
        <title>Mehmet Davut</title>
        <meta name="description" content="I am Backend Developer at Mazepay.com, Full-Stack Developer, DevOps, Entrepreneur, Investor and Astrologer." />
      </Helmet>

      <div className="container mx-auto p-3 md:max-w-lg">
        <h1 className="text-4xl font-bold uppercase tracking-widest mb-2">Mehmet Davut</h1>
        <div className="flex mb-5">
          <div className="w-1/4 pr-2">
            <img src={md} className="rounded-full w-auto" alt="Mehmet Davut" />
          </div>

          <div className="w-3/4 pl-1 text-lg">
            I am Backend Developer at <a href="https://www.mazepay.com" className="text-blue-600 hover:text-blue-500 focus:text-blue-700" target="_blank" rel="noreferrer noopener nofollow">Mazepay.com</a>, Full-Stack Developer, Blockchain Developer, DevOps, Entrepreneur, Investor and Astrologer.
          </div>
        </div>

        <div className="mb-10">
          You can find me on  <a href="https://twitter.com/MehmetDavut" className="text-blue-600 hover:text-blue-500 focus:text-blue-700" target="_blank" rel="noreferrer noopener nofollow">Twitter</a> or <a href="https://www.linkedin.com/in/mehmetdavut" className="text-blue-600 hover:text-blue-500 focus:text-blue-700" target="_blank" rel="noreferrer noopener nofollow">LinkedIn</a>.
        </div>


        <div className="mb-10">
          <strong>Past</strong>
          <h2 className="text-xl font-bold">Full-Stack Developer</h2>
          <p className="mb-3">
            I've developed web applications for several startups in Turkey, such as </p>

            <h3 className="text-lg font-bold">Startsub.com</h3>

            <p className="mb-3">
              <a href="https://www.startsub.com" target="_blank" rel="noreferrer noopener" className="text-blue-600 hover:text-blue-500 focus:text-blue-700">Startsub.com</a> is a subscription based e-commerce platform. Merchants can create & publish their subscription based e-commerce by using startsub.com substructure.
            </p>

            <p className="mb-3">
              <a href="https://www.aydanaya.com" target="_blank" rel="noreferrer noopener" className="text-blue-600 hover:text-blue-500 focus:text-blue-700">Aydanaya.com</a> is the marketplace for these products. The products are developed by using Ruby on Rails, Vue.js on AWS.
            </p>

            <h3 className="text-lg font-bold">Global Miles</h3>

            <p className="mb-3">
              <a href="https://www.globalmiles.com" className="text-blue-600 hover:text-blue-500 focus:text-blue-700" rel="noreferrer noopener nofollow" target="_blank">Globalmiles.com</a> is a blockchain based loyalty system which you can earn points from your fligts and shopping and spend them to buy airline tickets. It is an application for iOS and Android, I've developed their APIs with Ruby on Rails and smart contract for ERC-20 token on Ethereum platform, also leaded Jr. Developers and maintain servers.
            </p>

            <h3 className="text-lg font-bold">Startups.watch</h3>
            <p>
              <a href="https://startups.watch" className="text-blue-600 hover:text-blue-500 focus:text-blue-700" rel="noreferrer noopener nofollow" target="_blank">Startups.watch</a> is a data platform for Turkish startup ecosystem. It presents all informations about startup ecosystem including startups, VCs, accelerators, investments, acquisitions and so on. I have developed it by using Ruby on Rails.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-xl font-bold">Blockchain Developer</h2>
            <p className="mb-3">I've developed some smart contracts on Ethereum platform for startups</p>

            <h3 className="text-lg font-bold">GMile</h3>
            <p className="mb-3">
              <a href="https://etherscan.io/token/0x8ba49452e12449240425de9895b1aa51f5f3b90d" target="_blank" rel="noreferrer noopener nofollow" className="text-blue-600 hover:text-blue-500 focus:text-blue-700">GMile</a> was one of the first Smart Contract which was developed in Turkey and one of the top 50 contracts in Ethereum network by transaction count. I've published it in March 2017 for GlobalMiles to store and distribute bonus points for their customers.
            </p>

            <h3 className="text-lg font-bold">Kimlic</h3>
            <p>
              <a href="https://www.kimlic.com/" target="_blank" rel="noreferrer noopener nofollow" className="text-blue-600 hover:text-blue-500 focus:text-blue-700">Kimlic</a> was Digital Identity project which works on private Ethereum network to store and validate customers' identity for corporations like banks, post offices, etc. It won award <a href="https://youtu.be/7jIJCfJRszo?t=27m40s" target="_blank" rel="noreferrer nofollow" className="text-blue-600 hover:text-blue-500 focus:text-blue-700">Webrazzi Arena</a> 2017
            </p>
          </div>

          <div className="mb-10">
            <hr className="mb-2" />

            <p className="italic">
              I've been working with advertising agencies in Istanbul and developed microsites, social media apps and so on for big brands in Turkey like Kitchenette, Komşufırın, Nusr-et, Monochrome, Varyap Yalıkavak which you can see all in my <a href="https://blog.mehmetdavut.com/portfolio/" target="_blank" rel="noreferrer noopener" className="text-blue-600 hover:text-blue-500 focus:text-blue-700">Portfolio</a>.
            </p>
          </div>

          <div className="mb-10">
            <h3 className="text-lg font-bold">Author</h3>
            <p>
              I've been writing about technology at <a href="https://www.techinside.com/author/mehmet-davut/" target="_blank" rel="noreferrer noopener nofollow" className="text-blue-600 hover:text-blue-500 focus:text-blue-700">Techinside.com</a>. And also I've my <a href="https://blog.mehmetdavut.com/" target="_blank" rel="noreferrer noopener" className="text-blue-600 hover:text-blue-500 focus:text-blue-700">Personal Blog</a> writing about tech, Turkey and Morocco.
            </p>
          </div>

          <div className="mb-10">
            <h3 className="text-lg font-bold">Astrology</h3>
            <p>
              Since 2011, I read, join courses and train myself about Astrology. I've interpreted horoscope of more than 300 people and collect data about astrological knowledge.
            </p>
          </div>
        </div>
    </Layout>
  )
}

export default IndexPage
